<template>
  <div class="dataMigration-wrapper">
    <!--面包屑-->
    <div class="breadcrumb">
      <!--<el-breadcrumb separator="/">
                <el-breadcrumb-item>停车记录</el-breadcrumb-item>
                <el-breadcrumb-item>泊位视频识别分析</el-breadcrumb-item>
            </el-breadcrumb>-->
    </div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          label-width="90px"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('searchModule.region')">
            <el-select
              v-model.trim="formInline.areaId"
              filterable
              @change="areaChange"
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属街道">
            <el-select
              v-model.trim="formInline.streetId"
              filterable
              placeholder=""
              @change="
                $refs.parkInput.setValue('');
                formInline.operationId = '';
              "
              size="15"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList2"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.park_name')">
            <el-autocomplete
              class="inline-input"
              v-model="modelvalue"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入内容"
              value-key="parkName"
              :trigger-on-focus="false"
              @select="handleSelect"
            ></el-autocomplete>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.park_name')">
            <my-component
              ref="parkInput"
              :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
              :operationId="formInline.operationId"
              @valueChange="completeValue"
              slaveRelations="0,1"
              :parkNameValue="parkNameValue"
            >
            </my-component>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.Belonging_operator')">
            <el-select
              v-model.trim="formInline.operationId"
              placeholder="请选择"
              filterable
              size="15"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Commercial_status')">
            <el-select
              v-model.trim="formInline.commercialStatus"
              filterable
              @change="getRoleList"
              size="10"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in commercialStatus"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Creation_time')">
            <el-date-picker
              v-model="date1"
              type="datetime"
              placeholder="选择日期"
              :picker-options="pickerOptions1"
              @change="showLog"
            >
            </el-date-picker>
            至
            <el-date-picker
              v-model="date2"
              type="datetime"
              placeholder="选择日期"
              :picker-options="pickerOptions2"
              @change="showLog"
            >
            </el-date-picker>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            v-if="$route.meta.authority.button.query"
            >{{ $t('button.search') }}</el-button
          >
          <el-button icon="el-icon-delete" @click="clear()">{{ $t('button.reset') }}</el-button>
          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="exportList()"
            v-if="$route.meta.authority.button.export"
            >{{ $t('button.export') }}</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-upload"
            @click="dataMigartion"
            v-if="$route.meta.authority.button.detail"
            :loading="loading"
            >数据迁移</el-button
          >
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="30"></el-table-column>
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
        </el-table>
      </div>

      <!-- 提示信息 -->
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <span>正在迁移,请不要关闭此页面！</span>
      </el-dialog>

      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getDt, setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
// import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  name: "dataMigration",
  data() {
    // let date1 = new Date();
    // date1.setHours(0);
    // date1.setMinutes(0);
    // date1.setSeconds(0);
    // let startTime = date1;
    // let date = new Date();
    // date.setHours(23);
    // date.setMinutes(59);
    // date.setSeconds(59);
    // let endTime = date;
    return {
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() < this.data1 || time.getTime() > Date.now();
        },
      },
      pickerOptions2: {
        disabledDate: (time) => {
          return time.getTime() < this.data2 || time.getTime() > Date.now();
        },
      },
      dialogVisible: false,
      parkNameValue: "",
      parkType: [],
      commercialStatus: [],
      rowData: "",
      parkIds: [],
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      date1: "",
      date2: "",
      roleList: [],
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      tenantList: [],
      busiTypeList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "120",
        },
        {
          prop: "amount",
          label: this.$t("list.parking_number"),
          width: "100",
        },
        {
          prop: "slaveRelationName",
          label: "附属关系",
          width: "120",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "160",
        },
        {
          prop: "commercialStatus",
          label: this.$t("list.Commercial_status"),
          width: "180",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Creation_time"),
          width: "",
        },
      ],
      parkPage: 1,
      parkPageSize: 15,
      tableData: [],
      temParkData: {},
      modelvalue: "",
      formInline: {
        operationId: "",
        parkName: "",
        parkType: "",
        parkId: "",
        companyId: "",
        areaId: "",
        streetId: "",
        busiType: "",
        companyName: "",
        areaName: "",
        code: "",
        startTime: "",
        endTime: "",
        commercialStatus: "",
      },
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1,2",
            dataSource: 1,
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            // this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // 数据迁移http://10.100.16.109:8085/acb/2.0/migration/park/migratePark
    dataMigartion() {
      let parkIds = this.parkIds.join(",");
      if (parkIds == "" || parkIds == null) {
        this.$alert("请选择车场！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      this.loading = true;
      this.$prompt("请输入密码", this.$t('pop_up.Tips'), {
        confirmButtonText: "提交",
        showCancelButton: false,
      })
        .then(({ value }) => {
          this.dialogVisible = true;
          this.$axios
            .post(
              "/acb/2.0/migration/park/migratePark",
              {
                data: {
                  parkIds: parkIds,
                  accessKey: value,
                },
              },
              {
                timeout: 10000002,
              }
            )
            .then((res) => {
              this.loading = false;
              this.dialogVisible = false;
              console.log(res);
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            })
            .catch((e) => {
              this.dialogVisible = false;
              this.searchData();
            });
        })
        .catch((action) => {
          this.searchData();
        });
    },
    // 导出
    exportList() {
      let parkIds = this.parkIds.join(",");
      if (parkIds == "" || parkIds == null) {
        this.$alert("请选择车场！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      exportExcelNew("/acb/2.0/migration/park/exportPark", { parkIds: parkIds });
    },
    // 清空
    clear() {
      this.formInline.operationId = "";
      this.formInline.parkName = "";
      this.formInline.parkType = "";
      this.formInline.parkId = "";
      this.formInline.companyId = "";
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.busiType = "";
      this.formInline.companyName = "";
      this.formInline.areaName = "";
      this.formInline.code = "";
      this.formInline.startTime = "";
      this.formInline.endTime = "";
      this.formInline.commercialStatus = "";
      this.modelvalue = "";
      this.date1 = "";
      this.date2 = "";
    },
    // 全选的行数据
    handleSelectionChange(val) {
      console.log(val);
      this.parkIds = [];
      for (let i in val) {
        this.parkIds.push(val[i].parkId);
      }
    },
    completeValue(item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    // 初始化用户区域下拉列表
    initAreaList() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    areaChange(item) {
      //   this.$refs.parkInput.setValue("");
      this.parkPage = 1;
      //   this.streetList = [];
      //   this.streetId = "";
      this.setParkNull();
      //   if (!areaId) return;
      //   this.getStreet(areaId);
      //   this.formInline.operationId = "";
      //   this.getChildrenArea(item);
    },
    // 操作细则
    handleCommand(command) {
      if (command == 1) {
        if (this.rowData.slaveRelation != 1) {
          // 跳转封闭车场详情
          if (this.rowData.parkType == 3) {
            this.$router.push({
              path: "/closeChildrenParkDetail",
              query: this.rowData,
            });
          } else {
            // 跳转非封闭车场详情
            this.$router.push({
              path: "/openChildrenParkDetail",
              query: this.rowData,
            });
          }
        } else {
          if (this.rowData.parkType == 3) {
            // 跳转封闭父车场详情
            this.$router.push({
              path: "/closeParentParkDetail",
              query: this.rowData,
            });
          } else {
            // 跳转非封闭父车场详情
            this.$router.push({
              path: "/openParentParkDetail",
              query: this.rowData,
            });
          }
        }
      } else if (command == 2) {
        if (this.rowData.parkType == 3) {
          this.$router.push({
            path: "/closeConfigPark",
            query: this.rowData,
          });
        } else {
          this.$router.push({
            path: "/openConfigPark",
            query: this.rowData,
          });
        }
      }
    },
    // 选择每行数据
    getRowData(data) {
      this.rowData = data;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog(data) {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime || this.date1;
        this.formInline.endTime = endTime || this.date2;
        var time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 30 && this.formInline.mobile == "" && this.formInline.plateNumber == "") {
          alert("仅能查出30天内的记录");
          return false;
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          alert("开始时间不能大于结束时间");
          return false;
        } else {
          return true;
        }
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
        // this.$alert('日期不能为空', this.$t('pop_up.Tips'), {
        //   confirmButtonText: this.$t('pop_up.Determine')
        // });
        // return false
      }
    },
    //      showDetail (index, obj) {
    //        this.$router.push({
    //          path: '/parkDetail',
    //          query: obj
    //        })
    //      },
    // 获取区域
    getArea() {
      let opt = {
        url: "/boss/2.0/area/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.areaList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    // 运营商类型列表
    getBusitype() {
      let opt = {
        url: "/boss/2.0/busiType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.busiTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      getDt(opt);
    },
    /* 运营商名称的下拉框 */
    getRoleList() {
      // let sortByKey = (array, key) => {
      //   return array.sort(function(a, b) {
      //     var x = a[key];
      //     var y = b[key];
      //     return sortPinyin.compareInPinYin(x, y)
      //   })
      // }
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        this.loading = false;
        this.roleList = res.value;
        console.log(this.roleList);
      });
    },
    getChildrenArea(areaId) {
      if (!areaId) {
        this.areaList2 = [];
        this.formInline.streetId = "";
        return;
      }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            areaIds:
              this.formInline.streetId != "" ? this.formInline.streetId : this.formInline.areaId,
            slaveRelations: "0,1",
            parkName: queryString,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
        for (let i = 0; i < res.value.parkCommercialStatus.length; i++) {
          this.commercialStatus.push(res.value.parkCommercialStatus[i]);
        }
      });
    },
    // 搜索
    searchData() {
      //   this.$refs.parkInput.setShowVal(this.formInline.parkName);
      this.loading = false;
      this.tableData = [];
      this.formInline.page = this.pageNum;
      this.formInline.pageSize = this.pageSize;
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      this.$axios
        .get("/acb/2.0/park/list", {
          data: this.formInline,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
        });
    },
  },
  components: {
    // myComponent
  },
  activated() {
    this.searchData();
  },
  beforeDestroy() {
    // sessionStorage.searchData = JSON.stringify(this.formInline);
    // sessionStorage.searchFlag = 1;
  },
  created() {
    // if (sessionStorage.searchFlag == 1) {
    //   console.log(sessionStorage.searchData);
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    //   this.parkNameValue = this.formInline.parkName;
    // }
    // 初始化街区
    this.initAreaList();
    this.getRoleList();
    // this.parkData();
    // this.getBusitype();
    this.getParkSpinner();
  },
  mounted() {
    console.log(this.$route.meta.authority);
    this.searchData();
  },
  computed: {},
};
</script>
<style lang="stylus">
.dataMigration-wrapper{
    .el-checkbox:last-of-type {
      margin-left: 2px;
    }
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    background #EFF2F7
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
